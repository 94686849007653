<template>
  <div>
    <ModuleComponent
      ref="mc"
      name="lands"
      @changed="item = $event"
      :module-data="moduleData"
      :hide-filters="hideFilters"
      :no-bread-crumb="noBreadCrumb"
      @beforeSaveItem="beforeSaveItem"
      @beforeItemLoad="beforeItemLoad"
    >
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.FARMER')">
                <v-select2
                  v-model="filters.farmerId.value"
                  endPoint="accounts"
                  :placeholder="t('GLOBAL.FARMER')"
                  :filters="{
                    accountTypeId: { value: Constants.AccountTypes.Farmer }
                  }"
                  sort="name"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input
                  name="name"
                  v-model="filters.name.value"
                  :placeholder="t('GLOBAL.NAME')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.STATUS')" class="mb-3">
                <vue-select
                  v-model="filters.active.value"
                  label="text"
                  :options="[
                    { text: t('GLOBAL.ACTIVE'), value: true },
                    { text: t('GLOBAL.PASSIVE'), value: false }
                  ]"
                  :multiple="false"
                  :placeholder="t('GLOBAL.ALL')"
                >
                </vue-select>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="6" md="3">
              <b-form-group :label="t('GLOBAL.COUNTRY')" class="mb-3">
                <v-select2
                  v-model="filters.countryId.value"
                  :text="''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.COUNTRY')"
                  sort="order"
                  :filters="{ type: { value: 0 } }"
                  @input="
                    filters.cityId.value = null;
                    filters.countyId.value = null;
                    filters.districtId.value = null;
                  "
                  :name="t('GLOBAL.COUNTRY')"
                ></v-select2>
              </b-form-group>
            </b-col>

            <b-col cols="3" sm="6" md="3" v-if="filters.countryId.value > 0">
              <b-form-group :label="t('GLOBAL.CITY')" class="mb-3">
                <v-select2
                  v-model="filters.cityId.value"
                  :text="''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.CITY')"
                  :filters="{
                    type: { value: 1 },
                    parentId: { value: filters.countryId.value }
                  }"
                  @input="
                    filters.countyId.value = null;
                    filters.districtId.value = null;
                  "
                ></v-select2>
              </b-form-group>
            </b-col>

            <b-col cols="3" sm="6" md="3" v-if="filters.cityId.value > 0">
              <b-form-group :label="t('GLOBAL.COUNTY')" class="mb-3">
                <v-select2
                  v-model="filters.countyId.value"
                  :text="''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.COUNTY')"
                  :filters="{
                    type: { value: 2 },
                    parentId: { value: filters.cityId.value }
                  }"
                  @input="filters.districtId.value = null"
                ></v-select2>
              </b-form-group>
            </b-col>

            <b-col cols="3" sm="6" md="3" v-if="filters.countyId.value > 0">
              <b-form-group :label="t('GLOBAL.DISTRICT')" class="mb-3">
                <v-select2
                  v-model="filters.districtId.value"
                  :text="''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.DISTRICT')"
                  :filters="{
                    type: { value: 3 },
                    parentId: { value: filters.countyId.value }
                  }"
                ></v-select2>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:tableRowActions="{ rowItem }"></template>

      <template v-slot:customColumn_active="{ rowItem }">
        <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
      </template>

      <template v-slot:customColumn_croplands="{ rowItem }">
        <v-btn
          color="blue-grey"
          x-small
          dark
          class="mr-1"
          @click="showCropLandsOfLandDialog(rowItem)"
        >
          <v-icon x-small dark>
            mdi-border-all
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:form="{ item }">
        <div class="mt-3 form-group-mb-0">
          <b-row>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.FARMER')">
                <v-select2
                  v-model="item.farmerId"
                  :text="
                    item.farmer && item.farmer.account
                      ? item.farmer.account.name
                      : ''
                  "
                  endPoint="accounts"
                  :placeholder="t('GLOBAL.FARMER')"
                  :filters="{
                    accountTypeId: { value: Constants.AccountTypes.Farmer }
                  }"
                  sort="name"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.LANDNAME')">
                <b-form-input v-model="item.name"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.AREA')">
                <b-input-group>
                  <b-form-input
                    type="number"
                    v-model="item.area"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text>m<sup>2</sup></b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.ROUNDABOUT')">
                <b-form-input v-model="item.roundAbout"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.PLOT')">
                <b-form-input v-model="item.plot"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="4" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.SHEET')">
                <b-form-input v-model="item.sheet"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4" sm="12" md="4">
              <b-form-group :label="t('GLOBAL.KIND')" class="mb-3">
                <b-form-input v-model="item.kind"></b-form-input>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.AREATKGM')" class="mb-3">
                <b-form-input v-model="item.areaTkgm"></b-form-input>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.PLOTID')" class="mb-3">
                <b-form-input v-model="item.plotId"></b-form-input>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.PLACE')" class="mb-3">
                <b-form-input v-model="item.place"></b-form-input>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.COUNTRY')" class="mb-3">
                <v-select2
                  v-model="item.countryId"
                  :text="item.country ? item.country.name : ''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.COUNTRY')"
                  sort="order"
                  :filters="{ type: { value: 0 } }"
                  @input="
                    item.cityId = 0;
                    item.countyId = 0;
                    item.districtId = 0;
                  "
                ></v-select2>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.CITY')" class="mb-3">
                <v-select2
                  v-model="item.cityId"
                  :text="item.city ? item.city.name : ''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.CITY')"
                  :filters="{
                    type: { value: 1 },
                    parentId: { value: item.countryId }
                  }"
                  @input="
                    item.countyId = 0;
                    item.districtId = 0;
                  "
                ></v-select2>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.COUNTY')" class="mb-3">
                <v-select2
                  v-model="item.countyId"
                  :text="item.county ? item.county.name : ''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.COUNTY')"
                  :filters="{
                    type: { value: 2 },
                    parentId: { value: item.cityId }
                  }"
                  @input="item.districtId = 0"
                ></v-select2>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.DISTRICT')" class="mb-3">
                <v-select2
                  v-model="item.districtId"
                  :text="item.district ? item.district.name : ''"
                  endPoint="locations"
                  :placeholder="t('GLOBAL.DISTRICT')"
                  :filters="{
                    type: { value: 3 },
                    parentId: { value: item.countyId }
                  }"
                ></v-select2>
              </b-form-group>
              <b-form-group :label="t('GLOBAL.ADDRESS')" class="mb-3">
                <b-form-textarea
                  rows="5"
                  v-model="item.address"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col cols="8" sm="12" md="8">
              <b-form-group :label="t('GLOBAL.LOCATION')">
                <Map
                  v-if="item"
                  mapTypeId="satellite"
                  :lat="polygon && polygon.length > 0 ? polygon[0].lat : 0"
                  :lng="polygon && polygon.length > 0 ? polygon[0].lng : 0"
                  :paths="polygon"
                  draw-polygons
                ></Map>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <Upload v-model="item.image" single :title="t('GLOBAL.IMAGE')" />
            </b-col>
          </b-row>
        </div>
      </template>
    </ModuleComponent>
    <dlg v-model="showCropLands" eager>
      <template v-slot:content>
        <CropLands ref="cropLands" hide-filters no-bread-crumb></CropLands>
      </template>
    </dlg>
  </div>
</template>
<script>
// http://core.datadns.net:5000/swagger/index.html#model-Land
import CropLands from "./croplands";

export default {
  name: "lands",
  components: { CropLands },
  methods: {
    beforeItemLoad(item) {
      this.polygon = JSON.parse(item.polygon);
    },
    beforeSaveItem(item) {
      if (this.polygon.length > 0) {
        item.lat = this.polygon[0].lat;
        item.lng = this.polygon[0].lng;
      }
      item.polygon = JSON.stringify(this.polygon);
    },
    filterByFarmer(farmer) {
      this.moduleData.table.filters.farmerId.value = farmer.id;
    },
    showCropLandsOfLandDialog(land) {
      this.showCropLands = true;
      this.$refs.cropLands.filterByLand(land);
    }
  },
  data() {
    return {
      polygon: null,
      showComments: false,
      showCropLands: false,
      item: {},
      moduleData: {
        name: "lands",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
				            id,
				            farmer{account{name}},
				            country{name},
				            city{name},
				            county{name},
				            district{name},
				            name,
				            area,
				            active
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.FARMER"), value: "farmer.account.name" },
            { text: this.t("GLOBAL.COUNTRY"), value: "country.name" },
            { text: this.t("GLOBAL.CITY"), value: "city.name" },
            { text: this.t("GLOBAL.COUNTY"), value: "county.name" },
            { text: this.t("GLOBAL.DISTRICT"), value: "district.name" },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            { text: this.t("GLOBAL.AREA"), value: "area" },
            {
              text: this.t("GLOBAL.CROPLAND"),
              value: "croplands",
              width: "60"
            },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active", "croplands"],
          filters: {
            name: {
              type: "string",
              searchBy: "contains",
              value: null
            },
            farmerId: {
              type: "number",
              value: null
            },
            countryId: {
              field: "countryId",
              type: "number",
              value: null
            },
            cityId: {
              field: "cityId",
              type: "number",
              value: null
            },
            countyId: {
              field: "countyId",
              type: "number",
              value: null
            },
            districtId: {
              field: "districtId",
              type: "number",
              value: null
            },
            active: {
              field: "active",
              type: "bool",
              value: null
            }
          }
        },
        form: {
          fullScreen: true
        },
        fileManager: "land_file_groups"
      }
    };
  }
};
</script>
